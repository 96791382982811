import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import IntroSection from './IntroSection';
import ScrollHint from '../../components/ScrollHint';
import VideoBlock from '../../components/VideoBlock';
import { respondTo } from '../../utils/responsive';

const HomePage = () => {
  return (
    <Root>
      <Logo>
        <Link className="logo" to="/">
          <img src="http://placehold.it/125x50?text=LOGO" alt="" />
          <img className="mobile" src="http://placehold.it/62x25?text=LOGO" alt=""/>
        </Link>
      </Logo>
      <Heading>
        <ScrollDownHint />
        <VideoBlock url="http://drive.google.com/uc?export=view&id=1UD8qOxJw8OzmAWTt8jgQPKnVazwHFs_b" />
      </Heading>
      <IntroSection />
    </Root>
  )
}

const Root = styled.div`

`

const Heading = styled.div`
  position: relative;
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 24px;
  height: 88px;
  ${respondTo.md} {
    height: 72px;
  }
  .logo {
    position: relative;
    display: flex;
    width: 125px;
    z-index: 3;
    ${respondTo.md} {
      width: 80px;
    }
    img {
      width: 100%;
      height: auto;
      ${respondTo.md} {
        display: none;
      }
    }
    img.mobile {
      display: none;
      ${respondTo.md} {
        display: block;
      }
    }
  }
`

const ScrollDownHint = styled(ScrollHint)`
  position: absolute;
  margin-left: -8px;
  left: 100%;
  bottom: 10vh;
  transform: rotate(-90deg);
  transform-origin: bottom left;
  white-space: nowrap;
  z-index: 2;
  ${respondTo.md} {
    display: none;
  }
`

export default HomePage;