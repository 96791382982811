import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { DropdownArrow } from '../../components/Icons';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';

const FAQSection = ({ ...props }) => {
  const [ current, setCurrent ] = useState(null);
  const wording = _w('mint.faq')
  

  function handleClickItem(e) {
    const index = Number(e.currentTarget.dataset.index);
    setCurrent(index)
  }
  
  return (
    <Root {...props}>
      <Container>
        <Title>FAQ//</Title>
        <List>
          { wording.list.map((item, i) => 
            <li key={i}>
              <Item open={current === i}>
                <div className="question" data-index={i} onClick={handleClickItem}>
                  { item.question }
                  <DropdownArrow />
                </div>
                <div className="answer">{ item.answer }</div>
              </Item>
            </li>
          ) }
        </List>
      </Container>
    </Root>
  )
}

const Root = styled.div`
  margin: 0 auto;
  border-top: 1px solid ${colors.white};
  padding: 0 34px;
  padding-top: 90px;
  padding-bottom: 90px;
  width: 1200px;
  max-width: 100%;
  box-sizing: border-box;
  ${respondTo.md} {
    padding: 0;
    padding-top: 64px;
    padding-bottom: 54px;
    margin: 0 36px;
    width: calc(100% - 72px);
  }
`

const Container = styled.div`
  margin: 0 auto;
  padding: 0 100px;
  width: 1200px;
  max-width: 100%;
  box-sizing: border-box;
  ${respondTo.md} {
    padding: 0;
  }
`

const Title = styled.div`
  font-family: 'Roboto Condensed';
  font-weight: 700;
  font-size: 24px;
  font-style: italic;
  color: ${colors.white};
  text-transform: uppercase;
  ${respondTo.md} {
    margin: 0;
    font-size: 28px;
    text-align: center;
  }
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -50px;
  margin-top: 40px;
  ${respondTo.md} {
    display: block;
    margin: 0;
    margin-top: 16px;
    padding: 0 10px;
  }
  > li {
    padding: 0 50px;
    margin-bottom: 80px;
    width: 50%;
    box-sizing: border-box;
    ${respondTo.md} {
      margin-bottom: 28px;
      padding: 0;
      width: 100%;
    }
  }
`

const Item = styled.div`
  padding-left: 20px;
  ${respondTo.md} {
    border: 1px solid #595959;
    border-radius: 12px;;
    padding: 8px 16px;
    padding-left: 36px;
  }
  .question {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      ${respondTo.md} { color: #8C8C8C; }
    }
    &:before {
      content: "";
      position: absolute;
      top: 12px;
      left: -18px;
      display: block;
      width: 4px;
      height: 4px;
      background: ${colors.white};
      border-radius: 50%;
    }
    cursor: pointer;
    color: ${colors.white};
    line-height: 28px;
    font-size: 18px;
    font-weight: 600;
    ${respondTo.md} {
      line-height: 28px;
      font-size: 16px;
    }
  }
  .answer {
    color: #8C8C8C;
    line-height: 28px;
    font-size: 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease;
    ${({ open }) => open && css`
      padding-top: 16px;
      max-height: 100vh;
    `}
    ${respondTo.md} {
      line-height: 18px;
      font-size: 12px;
    }
  }
`


export default FAQSection;