import React from "react";
import styled from 'styled-components';
import { _w } from '../../utils/wordingSystem';
import { colors } from '../../constants/colors';
import { respondTo } from "../../utils/responsive";

const InfoSection = () => {
  const wording = _w('mint');
  
  return (
    <Root>
      <Info>
        <div className="title">{ wording.title }</div>
        <div className="content">{ wording.content }</div>
      </Info>
      <PhotoCard>
        <img src="/images/mint-info.png" alt="" />
      </PhotoCard>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 80px;
  margin-top: 108px;
  padding: 0 40px;
  width: 1200px;
  max-width: 100%;
  box-sizing: border-box;
  ${respondTo.md} {
    display: block;
    margin-top: 72px;
    margin-bottom: 56px;
  }
`

const Info = styled.div`
  padding-right: 80px;
  box-sizing: border-box;
  width: 50%;
  ${respondTo.md} {
    padding: 0;
    width: 100%;
  }
  .title {
    margin-bottom: 24px;
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 24px;
    font-style: italic;
    color: ${colors.white};
    ${respondTo.md} {
      margin-bottom: 16px;
      font-size: 18px;
    }
  }
  .content {
    color: ${colors.white};
    font-size: 16px;
    line-height: 24px;
  }
`

const PhotoCard = styled.div`
  width: 50%;
  padding-left: 80px;
  box-sizing: border-box;
  ${respondTo.md} {
    display: none;
  }
  > img {
    display: block;
    width: 380px;
    height: auto;
    max-width: 100%;
  }
`

export default InfoSection;