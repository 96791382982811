import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { useWordingLoader } from './utils/wordingSystem';

import appStore from './store/app';
// import walletStatus from './store/walletStatus';

import GlobalStyle from './components/GlobalStyle';
import AutoScrollHelper from './components/AutoScrollHelper';
import ScrollToTopHelper from './components/ScrollToTopHelper';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import MintPage from './pages/MintPage';
import { scrollTo } from './utils/scrollTo';

const reducer = combineReducers({
  app: appStore.reducer,
  // walletStatus: walletStatus.reducer,
  // scrollSpy: scrollSpy.reducer
});

const store = createStore(reducer);

const App = ({ wording, Router = BrowserRouter }) => {
  const wordingLoaded = useWordingLoader(wording ?? '/wordings/main.json');

  return (
    <Provider store={store}>
      <Helmet>
        <title>Black Banana</title>
        <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" rel="stylesheet"></link>
        <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" rel="stylesheet"></link>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;500;700;900&display=swap" rel="stylesheet"></link>
      </Helmet>
      <GlobalStyle/>
      <Router>
        { !wordingLoaded &&
          <div>Loading...</div>
        }
        { wordingLoaded &&
          <>
            <ScrollToTopHelper />
            <AutoScrollHelper />
            <Switch>
              <Route path="/" exact={true} component={HomePage} />
              <Route path="/mint" exact={true} component={MintPage} />
            </Switch>
            <Footer />
          </>
        }
      
      </Router>
    </Provider>
  );
}

export default App;
