import React, { useState } from "react";
import styled from 'styled-components';
import { _w } from '../../utils/wordingSystem';
import { colors } from '../../constants/colors';
import Carousel from '../../components/Carousel';
import SubscribeBlock from '../../components/SubscribeBlock';
import MintBlock from './MintBlock';
import { respondTo } from "../../utils/responsive";

const data = [
  { id: 1, price: 0.1, photo: "/images/homepage-ape_card.png" },
  { id: 2, price: 0.2, photo: "/images/homepage-ape_card.png" },
  { id: 3, price: 0.3, photo: "/images/homepage-ape_card.png" },
  { id: 4, price: 0.4, photo: "/images/homepage-ape_card.png" }
]

const ProductSection = () => {
  const wording = _w('mint');
  const [ currentIndex, setCurrentIndex ] = useState(0);

  const handleChangeSelector = (index) => {
    setCurrentIndex(index)
  }
  
  return (
    <Root>
      <PurchaseBlock>
        <Selector>
          <Carousel onChange={handleChangeSelector}>
            { data.map((item, i) =>
              <Card key={i}><img src={item.photo} /></Card>
            ) }
          </Carousel>
        </Selector>
        <Mint>
          <MintBlock price={data[currentIndex].price} className="mint" />
        </Mint>
      </PurchaseBlock>
      <Subscribe>
        <div className="title">{ wording.connect }</div>
        <SubscribeBlock size="lg" />
      </Subscribe>
    </Root>
  )
}

const Root = styled.div`
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  box-sizing: border-box;
`

const PurchaseBlock = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 80px 80px;
  width: 100%;
  box-sizing: border-box;
  ${respondTo.md} {
    display: block;
    padding: 80px 0;
  }
`

const Selector = styled.div`
  width: 50%;
  ${respondTo.md} {
    width: 100%;
  }
`

const Mint = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  ${respondTo.md} {
    margin-top: 68px;
    width: 100%;
  }
`

const Card = styled.div`
  margin: 0 auto;
  width: 420px;
  ${respondTo.md} {
    width: 300px;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`


const Subscribe = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 45px 80px;
  background: ${colors.brandYellow};
  box-sizing: border-box;
  ${respondTo.md} {
    display: block;
    margin: 0 32px;
    padding: 30px 40px;
    width: calc(100% - 64px);
    text-align: center;
  }
  .title {
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 24px;
    font-style: italic;
    color: ${colors.black};
    text-transform: uppercase;
    ${respondTo.md} {
      margin-bottom: 16px;
      font-size: 18px;
      letter-spacing: ${16 * 0.012}px;
      white-space: nowrap;
    }
  }
`

export default ProductSection;