import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '../../constants/colors';
import { ArrowBack } from '../Icons';
import { respondTo } from '../../utils/responsive';

const ScrollHint = ({ ...props }) => {
  return (
    <Root {...props}>
      <ArrowBack />
      <p>SCROLL DOWN</p>
    </Root>
  )
}

const flash = keyframes`
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const Root = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.white};
  font-size: 14px;
  z-index: 1;
  animation: ${flash} 2s steps(1) infinite;
  svg {
    margin-right: 4px;
  }
`

export default ScrollHint;