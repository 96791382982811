import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { respondTo } from '../../utils/responsive';
import { colors } from '../../constants/colors';
// import useConnectWallet from '../../utils/useConnectWallet';

const LinksList = ({ data, onLinkClick, ...props }) => {
  // const { status, connectId } = useConnectWallet;
  
  return (
    <List {...props}>
      { data.map((link, i) =>
        <LinkItem key={i} onClick={onLinkClick} to={link.to} showForMobile={link.hide === 'desktop'}>{ link.title }</LinkItem>
        ) }
      <LinkItem as="button" onClick={onLinkClick}>
        {/* { status === 'sucessed' ? connectId : 'CONNECT WALLET' } */}
        CONNECT WALLET
      </LinkItem>
    </List>
  )
}

const List = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  ${respondTo.md} {
    display: block;
    margin-bottom: 80px;
    height: auto;
    text-align: center;
  }
`

const LinkItem = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 20px;
  border: 0;
  padding: 0 10px;
  height: 100%;
  background: transparent;
  font-size: 16px;
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  color: ${colors.white};
  transition: color .3s ease;
  letter-spacing: ${16 * 0.012}px;
  &:last-child {
    margin-right: 0;
  }
  &:before {
    content: "";
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${colors.white};
    transition: opacity .3s ease;
  }
  ${respondTo.md} {
    display: block;
    margin: 0;
    margin-bottom: 50px;
    padding: 0;
    width: 100%;
    height: auto;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    text-align: center;
  }

  ${({ showForMobile }) => showForMobile && css`
    display: none;
  `}

  ${ respondTo.md } {
    display: block;
    ${({ showForMobile }) => showForMobile && css`
      display: block;
    `}
  }
`

export default LinksList;