import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';
import { ArrowLine } from '../Icons';

const SubscribeBlock = ({ size, show }) => {
  return (
    <Root size={size}>
      {
        show === 'title' &&
        <div className="title">Subscribe for latest update//</div>
      }
      <div className="input">
        <input type="email" placeholder="Email Address" />
        <button><ArrowLine /></button>
      </div>
    </Root>
  )
}

const Root = styled.div`
  color: ${colors.white};
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  > .title {
    margin-bottom: 8px;
    ${respondTo.md} {
      text-align: center;
    }
  }
  > .input {
    display: flex;
    align-items: center;
    ${respondTo.md} {
      flex-direction: row;
      justify-content: center;
    }
    > input {
      border: 0;
      border-radius: 4px 0px 0px 4px;
      padding: 4px 8px;
      height: 24px;
      min-width: 210px;
      font-family: 'Roboto Condensed';
      font-style: italic;
      font-weight: 300;
      font-size: 14px;
      color: ${colors.white};
      background: #272626;
      box-shadow: none;
      box-sizing: border-box;
      ${({ size }) => size === 'lg' && css`
        min-width: calc(280px - 25px);
        height: 40px;
        ${respondTo.md} {
          min-width: calc(240px - 25px);
        }
      `};
      ${respondTo.md} {
        height: 34px;
      }
      ::placeholder {
        color: #8C8C8C;
      }
    }
    > button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 0;
      border-radius: 0px 4px 4px 0px;
      width: 25px;
      height: 24px;
      background: #4B4A4A;
      color: ${colors.white};
      ${({ size }) => size === 'lg' && css`
        width: 40px;
        height: 40px;
      `};
      ${respondTo.md} {
        width: 34px;
        height: 34px;
      }
    }
  }
`

export default SubscribeBlock;