import React from "react";
import styled from 'styled-components';
import Header from "../../components/Header";
import VideoBlock from "../../components/VideoBlock";
import InfoSection from './InfoSection';
import ConnectSection from './ConnectSection';
import ShareSection from './ShareSection';
import FAQSection from './FAQSection';
import { _w } from '../../utils/wordingSystem';
import ProductSection from "./ProductSection";

const MintPage = () => {
  return (
    <Root>
      <Header />
      {/* <VideoBlock url={"https://drive.google.com/uc?export=download&id=1YquhLggfBnds3E-Y5Vnb25VhokgJKzp3"} /> */}
      <InfoSection />
      <ConnectSection />
      <ProductSection />
      <ShareSection />
      <FAQSection id="faq" />
    </Root>
  )
}

const Root = styled.div`
  
`

export default MintPage;