import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { _w } from '../../utils/wordingSystem';
import { colors } from '../../constants/colors';
import Button from '../../components/Button';
import { respondTo } from '../../utils/responsive';

const IntroSection = () => {
  const wording = _w('homepage.intro')
  
  return (
    <Root>
      <Container>
        <PhotoCard>
          <img src="/images/homepage-ape_card.png" alt="" />
        </PhotoCard>
        <Info>
          <div className="title">{ wording.title }</div>
          <div className="heading">{ wording.heading }</div>
          <div className="content">{ wording.content }</div>
          <Button className="link" as={Link} to='/mint'>MINT MY CARD</Button>
        </Info>
      </Container>
    </Root>
  )
}

const Root = styled.div`
  padding: 96px 0;
  background: ${colors.black};
  ${respondTo.md} {
    padding: 56px 0;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 0 auto;
  padding: 0 72px;
  width: 1200px;
  max-width: 100%;
  box-sizing: border-box;
  ${respondTo.md} {
    display: block;
    padding: 0 36px;
  }
`

const Info = styled.div`
  padding-right: 80px;
  box-sizing: border-box;
  width: 50%;
  ${respondTo.md} {
    padding: 0;
    width: 100%;
  }
  .title {
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 24px;
    font-style: italic;
    white-space: nowrap;
    color: ${colors.white};
    ${respondTo.md} {
      font-size: 18px;
      white-space: break-spaces;
    }
  }
  .heading {
    margin-bottom: 16px;
    font-family: 'Roboto';
    font-weight: 900;
    font-size: 56px;
    color: ${colors.brandYellow};
    white-space: nowrap;
    ${respondTo.md} {
      font-size: 32px;
    }
  }
  .content {
    margin-bottom: 40px;
    color: ${colors.white};
    font-size: 16px;
    line-height: 24px;
    padding: 0 8px;
    box-sizing: border-box;
  }
`

const PhotoCard = styled.div`
  width: 50%;
  padding-left: 80px;
  box-sizing: border-box;
  ${respondTo.md} {
    margin-bottom: 30px;
    padding: 0;
    width: 100%;
  }
  > img {
    display: block;
    width: 100%;
    height: auto;
  }
`

export default IntroSection;