import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/colors';

const VideoBlock = ({ url }) => {
  const videoRef = useRef(null);
  
  useEffect(() => {
    videoRef.current.onloadeddata = function() {
      videoRef.current.play();
    };
    videoRef.current.addEventListener('click', function() {
      console.log('work')
      videoRef.current.play();
    })
  }, [])
  
  return (
    <Root>
      <Video
        ref={videoRef}
        preload="auto"
        // autoPlay="false"
        muted
        // poster="//vjs.zencdn.net/v/oceans.png"
        playsInline
        loop
        controls={false}>
      <source src={url} type="video/mp4"></source>
      <p className="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">
          supports HTML5 video
        </a>
      </p>
    </Video>
    <img src="/images/loading.svg" alt="" />
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: 0px;
  background: ${colors.black};
  > img {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 24px;
    height: 24px;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
`

const Video = styled.video`
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  .custom-video-controls {
    z-index: 2147483647;
  }

  &::-webkit-slider-thumb {
    display:none !important;
  }
  &::-moz-range-track {
    display:none !important;
  }
  &::-moz-range-thumb {
    display:none !important;
  }
  &::-webkit-media-controls {
    display:none !important;
  }
  &::-webkit-media-controls-enclosure {
    display:none !important;
  }

`

export default VideoBlock;