import React from 'react';
import styled from 'styled-components';
import SubscribeBlock from '../SubscribeBlock';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem';
import { Twitter, Medium, Discord } from '../Icons';

const Footer = () => {
  const wording = _w('footer');

  return (
    <Root>
      <Container>
        <SocialList>
          <div className="icon-bar">
            { wording.socials.map((social, i) =>
              <SocialItem key={i} icon={social.icon} href={social.link} target="_blank" />
              ) }
          </div>
          <div className="copyright">{ wording.copyright }</div>
        </SocialList>
        <Logo>
          <img src="http://placehold.it/200x100?text=LOGO" alt="" />
        </Logo>
        <SubscribeBlock show="title" />
        <Copyright>{ wording.copyright }</Copyright>
      </Container>
    </Root>
  )
}


const SocialItem = ({icon, children, ...props}) => {
  return (
    <Item {...props}>
      { children }
      { icon === 'Twitter' && <Twitter /> }
      { icon === 'Medium' && <Medium /> }
      { icon === 'Discord' && <Discord /> }
      { icon === 'Investment' && <img src="/images/footer-ship-icon.png" /> }
    </Item>
  )
}

const Root = styled.div`
  box-sizing: border-box;
  ${respondTo.md} {
    display: block;
    padding: 0 32px;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin: 0 auto;
  border-top: 1px solid ${colors.white};
  padding: 24px 0;
  width: 1200px;
  max-width: 100%;
  box-sizing: border-box;
  ${respondTo.md} {
    display: block;
    padding: 32px 0;
  }
`

const Logo = styled.div`
  margin-left: -72px;
  ${respondTo.md} {
    display: none;
  }
`

const SocialList = styled.div`
  ${respondTo.md} {
    margin-bottom: 32px;
  }
  > .icon-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    ${respondTo.md} {
      margin-bottom: 0;
      justify-content: center;
    }
  }
  > .copyright {
    color: ${colors.white};
    font-family: "Roboto";
    font-weight: 300;
    font-size: 14px;
    ${respondTo.md} {
      display: none;
    }
  }
`

const Item = styled.a`
  display: flex;
  align-items: center;
  color: ${colors.white};
  width: 24px;
  transition: opacity .3s ease;
  ${respondTo.md} {
    width: 16px;
  }
  svg, img {
    display: block;
    width: 100%;
    height: auto;
  }
  & + a {
    margin-left: 12px;
    ${respondTo.md} {
      margin-left: 30px;
    }
  }
`

const Copyright = styled.div`
  display: none;
  margin-top: 32px;
  color: ${colors.white};
  font-family: "Roboto";
  font-weight: 300;
  font-size: 10px;
  text-align: center;
  ${respondTo.md} {
    display: block;
  }
`

export default Footer;