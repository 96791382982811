import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import LinksList from './LinksList';
import SocialList from './SocialList';
import { _w } from '../../utils/wordingSystem';
import { respondTo } from '../../utils/responsive';
import { lockWindow } from '../../utils/methods';
import { colors } from '../../constants/colors';

const Header = () => {
  const wording = _w('header');

  const headerRef = useRef(null);
  const fixederRef = useRef();

  const prevPageYOffset = useRef(0);
  const navbarTop = useRef(0);

  const [ open, setOpen ] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);    
    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, [])

  function handleWindowScroll(e) {
    const pageYOffset = Math.max(window.pageYOffset, 0);
    const delta = pageYOffset - prevPageYOffset.current;
    navbarTop.current = navbarTop.current - delta;
    navbarTop.current = Math.max(navbarTop.current, -160);

    if (delta < 0) navbarTop.current = 0;
    if (delta > 0) setOpen(false);
    fixederRef.current.style.setProperty('transition-duration', (delta < 0) ? '0.3s': '0s');
    fixederRef.current.style.setProperty('transform', `translateY(${navbarTop.current}px)`);

    prevPageYOffset.current = pageYOffset;
  }

  const handleCloseLinksMenu = () => {
    setOpen(false);
  }

  const handleToggleLinksMenu = () => {
    setOpen(!open);
  }

  useEffect(() => {
    lockWindow(open);
  }, [open])


  return (
    <Root ref={headerRef}>
      <Fixeder ref={fixederRef}>
        <Wrapper>
          <Link className="logo" to="/">
            <img src="http://placehold.it/125x50?text=LOGO" alt="" />
            <img className="mobile" src="http://placehold.it/62x25?text=LOGO" alt=""/>
          </Link>
          <MenuWrapper className="menu" open={open}>
            <LinksList data={wording.links} onLinkClick={handleCloseLinksMenu} />
            <SocialList data={wording.socials} />
          </MenuWrapper>
          <Heading>BLACK BANANA</Heading>
          <MenuButton open={open} onClick={handleToggleLinksMenu}>
            <div></div>
            <div></div>
            <div></div>
          </MenuButton>
        </Wrapper>
      </Fixeder>
    </Root>
  )
}

const Root = styled.header`
  height: 88px;
  ${respondTo.md} {
    height: 72px;
  }
`

const Fixeder = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding: 12px 24px;
  width: 100%;
  height: 88px;
  z-index: 5;
  box-sizing: border-box;
  background: #000000;
  color: ${colors.white};
  transition: transform .3s ease;
  box-shadow: 0px 4px 12px hsla(0, 0%, 0%, .5);
  ${respondTo.md} {
    height: 72px;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  transition: all .3s ease ${({time}) => time}ms;
  box-sizing: border-box;
  ${respondTo.md} {
    padding: 0;
  }
  .logo {
    position: relative;
    display: flex;
    align-items: center;
    width: 125px;
    height: 100%;
    z-index: 3;
    ${respondTo.md} {
      width: 80px;
    }
    img {
      width: 100%;
      height: auto;
      ${respondTo.md} {
        display: none;
      }
    }
    img.mobile {
      display: none;
      ${respondTo.md} {
        display: block;
      }
    }
  }
  .menu {
    /* width: 100%; */
    height: 100%;
    padding-left: 24px;
    box-sizing: border-box;
    ${respondTo.md} {
      width: 100vw;
      height: 100vh;
    }
  }
`

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${ respondTo.md } {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    right: 0;
    padding: 24px 40px;
    padding-top: 110px;
    width: 75vw;
    height: 100vh;
    overflow-y: auto;
    background: #000000;
    z-index: 2;
    transform: translateX(100%);
    transition: all .3s ease;
    box-sizing: border-box;
    ${({open}) => open && css`transform: translateX(0%);`}
  }
`

const MenuButton = styled.button`
  display: none;
  border: 0;
  height: 48px;
  background: transparent;
  z-index: 4;
  > div {
    margin: auto;
    width: 18px;
    height: 2px;
    background: #666666;
    transition: all .3s ease;
    & + div {
      margin-top: 4px;
    }
    ${({open}) => open && css`
      &:first-child { transform: translateY(6px) rotate(45deg);}
      &:nth-child(2) { opacity: 0; }
      &:last-child { transform: translateY(-6px) rotate(-45deg);}
    `}
  }
  ${ respondTo.md } {
    display: block;
  }
`

const Heading = styled.div`
  display: none;
  margin-left: -48px;
  font-family: 'Roboto Condensed';
  font-weight: 300;
  font-size: 14px;
  font-style: italic;
  color: ${colors.white};
  ${respondTo.md} {
    display: block;
  }
`

export default Header;
