import React from 'react';
import styled from 'styled-components';
import { _w } from '../../utils/wordingSystem';
import Button from '../../components/Button';
import { Download, Refresh, Twitter } from "../../components/Icons";
import { respondTo } from "../../utils/responsive";

const ProductSection = () => {
  return (
    <Root>
      <Selector>
        <Card><img src="/images/homepage-ape_card.png" /></Card>
      </Selector>
      <ShareBar>
        <Button className="refresh"><Refresh /></Button>
        <Button className="twitter"><Twitter /> SHARE YOUR NFT</Button>
        <Button className="download"><Download /></Button>
      </ShareBar>
    </Root>
  )
}

const Root = styled.div`
  margin: 0 auto;
  padding: 80px 0;
  width: 1200px;
  max-width: 100%;
  box-sizing: border-box;
  ${respondTo.md} {
    padding: 56px 0;
  }
`

const Selector = styled.div`
  margin: 0 auto;
  width: 100%;
`

const Card = styled.div`
  margin: 0 auto;
  width: 420px;
  ${respondTo.md} {
    width: 300px;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const ShareBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  ${respondTo.md} {
    margin-top: 12px;
  }
  button {
    margin: 0 8px;
    height: 64px;
    ${respondTo.md} {
      margin: 0 6px;
      height: 40px;
    }
    &.refresh {
      width: 64px;
      padding: 0;
      ${respondTo.md} {
        width: 40px;
      }
    }
    &.twitter {
      > svg {
        margin-right: 8px;
        width: 18px;
      }
    }
    &.download {
      min-width: 0px;
      padding: 0;
      width: 64px;
      ${respondTo.md} {
        width: 40px;
      }
    }
  }
`

export default ProductSection;