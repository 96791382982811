import React from "react";
import styled from 'styled-components';
import { _w } from '../../utils/wordingSystem';
import { colors } from '../../constants/colors';
import Button from '../../components/Button';
import useConnectWallet from "../../utils/useConnectWallet";
import { respondTo } from "../../utils/responsive";

const ConnectSection = () => {
  const wording = _w('mint');
  // const { status, displayId } = useConnectWallet();

  return (
    <Root>
      <Block>
        <div className="title">{ wording.connect }</div>
        <Button className="button" theme="dark">CONNECT WALLET</Button>
      </Block>
      <Hint>
        <img src="/images/icon-metamask.svg" alt="" />
        <p>You must install <a href="https://metamask.io/" target="_blank">Metamask</a>.</p>
      </Hint>
    </Root>
  )
}

const Root = styled.div`
  margin: 0 auto;
  padding: 45px 80px;
  width: 1200px;
  max-width: 100%;
  background: ${colors.brandYellow};
  box-sizing: border-box;
  ${respondTo.md} {
    margin: 0 32px;
    padding: 24px 0;
    width: calc(100% - 64px);
    text-align: center;
  }
`

const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${respondTo.md} {
    display: block;
  }
  .title {
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-size: 24px;
    font-style: italic;
    color: ${colors.black};
    text-transform: uppercase;
    ${respondTo.md} {
      margin-bottom: 8px;
      font-size: 18px;
      letter-spacing: ${18 * 0.012}px;
    }
  }
  .button {
    width: 280px;
    max-width: 100%;
    ${respondTo.md} {
      width: 200px;
    }
  }
`

const Hint = styled.div`
  display: none;
  margin-top: 12px;
  font-family: 'Roboto';
  font-weight: 300;
  ${respondTo.md} {
    display: block;
  }
  img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    width: 14px;
    height: auto;
  }
  p {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
  }
  a {
    text-decoration: underline;
  }
`

export default ConnectSection;