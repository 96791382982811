import React, { useState } from 'react';
import styled from 'styled-components';
import { Plus, Minus } from '../../components/Icons';
import Button from '../../components/Button';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { _w } from '../../utils/wordingSystem'

const MintSection = ({ price }) => {
  const [ qty, setQty ] = useState(1);
  
  function handleAddQty() {
    if (qty<=49){
      let currentQty = Math.max(qty+1, 1);
      setQty(currentQty);
    }
  }

  function handleDecreaseQty() {
    let currentQty = Math.max(qty-1, 1);
    setQty(currentQty);
  }
  
  function handleSetQty(value) {
    if (value<=50){
      setQty(value);
    } else {
      setQty(50);
    }
  }
  
  return (
    <Root>
      <div className="price">MINT: {price} ETH</div>
      <QtySelector qty={qty} 
        onPlusClick={handleAddQty}
        onMinusClick={handleDecreaseQty}
        onQtyInput={handleSetQty}
      />
      <div className="hint">wallet status hint here.</div>
      <div className="buttonbar">
        <Button>MINT</Button>
      </div>
    </Root>
  )
}


const QtySelector = ({ qty, onPlusClick, onMinusClick, onQtyInput }) => {

function handleChangeQty(e) {
  const value = Number(e.currentTarget.value);
  (value !== NaN && value >= 0) && onQtyInput(value);
}

return (
  <Selector>
    <QtyButton disabled={qty <= 1} onClick={onMinusClick}><Minus /></QtyButton>
    <input className="qty" type="text" value={qty} onChange={handleChangeQty} />
    <QtyButton disabled={false} onClick={onPlusClick}><Plus /></QtyButton>
  </Selector>
)
}

const Root = styled.div`
  text-align: center;
  .price {
    margin-bottom: 20px;
    font-size: 24px;
    color: ${colors.white};
    text-transform: uppercase;
    ${respondTo.md} {
      margin-bottom: 16px;
      font-size: 16px;
    }
  }
  .hint {
    margin-top: 16px;
    color: #8C8C8C;
    font-size: 12px;
    font-family: 'Roboto';
    font-weight: 300;
  }
  .buttonbar {
    text-align: center;
    margin-top: 30px;
    button {
      width: calc(100% - 12px);
      ${respondTo.md} {
        width: 108px;
      }
    }
    ${respondTo.md} {
      margin-top: 16px;
    }
  }
`

const Selector = styled.div`
  display: inline-flex;
  align-items: center;
  .qty {
    border: 2px solid ${colors.white};
    border-radius: 5px;
    height: 40px;
    width: 110px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 900;
    color: ${colors.white};
    background: transparent;
    text-align: center;
  }
`

const QtyButton = styled.button`
  border: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 1;
  background: transparent;
  color: ${colors.white};
  &:first-child {
    margin-right: 18px;
  }
  &:last-child {
    margin-left: 18px;
  }
`

export default MintSection