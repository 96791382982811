import React, { useRef } from 'react';
import Slick from 'react-slick';
import styled, { css } from 'styled-components';
import { colors } from '../../constants/colors';
import { respondTo } from '../../utils/responsive';
import { Arrow, ArrowBack } from '../Icons';

const Carousel = ({ className='', children, onChange }) => {
  const carouselRef = useRef(null);
  const slickSettings = {
    arrows: false,
    infinite: true,
    dots: true,
    slidesToShow: 1,
    dot: false,
    appendDots: dots => (
      <div className="nav-container">
        <button data-direction="prev" onClick={handleClickArrowButton}><ArrowBack /></button>
        <ul>{dots}</ul>
        <button data-direction="next" onClick={handleClickArrowButton}><Arrow /></button>
      </div>),
    afterChange: (index) => onChange(index)
  };

  function handleClickArrowButton(e) {
    if (carouselRef.current) {
      const { direction } = e.currentTarget.dataset;
      if (direction === 'prev') carouselRef.current.slickPrev();
      if (direction === 'next') carouselRef.current.slickNext();
    }
  }


  return (
    <Root >
      <CarouselBody>
        <Slick className={className} ref={carouselRef} {...slickSettings}>
          { children.map((child, i) => <div key={i}>{ child }</div>) }
        </Slick>
      </CarouselBody>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  padding-bottom: 24px;
  .slick-dots {
    display: flex;
    justify-content: center;
    button {
        border: 0;
        margin: 0;
        padding: 0;
        width: 24px;
        height: 24px;
        color: ${colors.white};
        background: transparent;
        svg {
          width: 6px;
        }
    }
    ul {
      li, button {
        border: 0;
        border-radius: 2px;
        margin: 0;
        padding: 0;
        width: 24px;
        height: 24px;
        color: ${colors.white};
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        font-family: 'Roboto';
        background: ${colors.black};
      }
      button:before {
        content: none;
      }
      .slick-active button {
        color: ${colors.black};
        background: ${colors.brandYellow};
      }

    }
  }
`

const CarouselBody = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
`

const NavContainer = styled.div`
  > button {
    position: absolute;
    top: 0;
    width: 24px;
    height: 24px;
    border: 0;
    background: ${colors.brandYellow};
    z-index: 2;
    &:first-child {
      left: 0;
    }
    &:last-child {
      right: 0;
    }
  }
`

export default Carousel;
